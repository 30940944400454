// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ExpenseTracker from './ExpenseTracker';
import ExpenseList from './ExpenseList';

function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <Link to="/">Add Expense</Link>
            </li>
            <li>
              <Link to="/list">List Expenses</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<ExpenseTracker />} />
          <Route path="/list" element={<ExpenseList />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
