// src/ExpenseList.js
import React, { useState, useEffect } from 'react';
import moment from 'moment';

const ExpenseList = () => {
    const [filterMonth, setFilterMonth] = useState('');
    const [expenses, setExpenses] = useState(JSON.parse(localStorage.getItem('expenses')) || []);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [editedExpense, setEditedExpense] = useState({ name: '', quantity: 0 });

    useEffect(() => {
        // Set filterMonth to current month when the component mounts
        setFilterMonth(moment().format('YYYY-MM'));
    }, []);

    const handleFilterChange = (e) => {
        setFilterMonth(e.target.value);
    };

    const handleEditClick = (index) => {
        setEditingIndex(index);
        setEditedExpense(expenses[index]);
    };

    const handleDeleteClick = (index) => {
        const updatedExpenses = expenses.filter((_, i) => i !== index);
        setExpenses(updatedExpenses);
        localStorage.setItem('expenses', JSON.stringify(updatedExpenses));
    };

    const handleSaveClick = () => {
        const updatedExpenses = expenses.map((expense, index) =>
            index === editingIndex ? editedExpense : expense
        );
        setExpenses(updatedExpenses);
        localStorage.setItem('expenses', JSON.stringify(updatedExpenses));
        setEditingIndex(-1);
        setEditedExpense({ name: '', quantity: 0 });
    };

    const handleCancelClick = () => {
        setEditingIndex(-1);
        setEditedExpense({ name: '', quantity: 0 });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedExpense({ ...editedExpense, [name]: value });
    };

    const filteredExpenses = filterMonth
        ? expenses.filter(expense =>
            moment(expense.date).format('YYYY-MM') === filterMonth
        )
        : expenses;

    const totalAmount = filteredExpenses.reduce((acc, expense) => acc + (expense.quantity * 65), 0);

    return (
        <div>
            <h1>Expenses for {moment(filterMonth).format('MMMM YYYY')}</h1>
            <div>
                <label>
                    Filter by Month:
                    <input
                        type="month"
                        value={filterMonth}
                        onChange={handleFilterChange}
                    />
                </label>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Expense Name</th>
                        <th>Quantity (kg)</th>
                        <th>Price per kg</th>
                        <th>Total</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredExpenses.map((expense, index) => (
                        <tr key={index}>
                            {editingIndex === index ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="name"
                                            value={editedExpense.name}
                                            onChange={handleEditChange}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            name="quantity"
                                            value={editedExpense.quantity}
                                            onChange={handleEditChange}
                                        />
                                    </td>
                                    <td>$65</td>
                                    <td>${editedExpense.quantity * 65}</td>
                                    <td>
                                        <button onClick={handleSaveClick}>Save</button>
                                        <button onClick={handleCancelClick}>Cancel</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{expense.name}</td>
                                    <td>{expense.quantity} kg</td>
                                    <td>Rs 65</td>
                                    <td>Rs {expense.quantity * 65}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(index)}>Edit</button>
                                        <button onClick={() => handleDeleteClick(index)}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="3">Total Amount</td>
                        <td colSpan="2">Rs {totalAmount}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default ExpenseList;
