// src/ExpenseTracker.js
import React, { useState } from 'react';

const ExpenseTracker = () => {
    const [expenses, setExpenses] = useState(JSON.parse(localStorage.getItem('expenses')) || []);
    const [expenseName, setExpenseName] = useState('');
    const [quantity, setQuantity] = useState(0);

    const handleAddExpense = () => {
        const pricePerKg = 65;
        const newExpense = {
            name: expenseName,
            quantity: quantity,
            amount: quantity * pricePerKg,
            date: new Date(),
        };
        const updatedExpenses = [...expenses, newExpense];
        setExpenses(updatedExpenses);
        localStorage.setItem('expenses', JSON.stringify(updatedExpenses));
        setExpenseName('');
        setQuantity(0);
    };

    return (
        <div>
            <h1>Expense Tracker</h1>
            <div>
                <label>
                    Expense Name:
                    <input
                        type="text"
                        value={expenseName}
                        onChange={(e) => setExpenseName(e.target.value)}
                    />
                </label>
            </div>
            <div>
                <label>
                    Quantity (kg):
                    <input
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </label>
            </div>
            <button onClick={handleAddExpense}>Add Expense</button>
        </div>
    );
};

export default ExpenseTracker;
